import {
  Avatar,
  Card,
  createStyles,
  Flex,
  Group,
  Image,
  rem,
  Text,
  Tooltip
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IUserDevice } from "../../../interfaces/IUserDevice";
import UserDevicesService from "../../../services/UserDevicesService";
import { genericError } from "../../../functions/genericError";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { faWifi } from "@fortawesome/pro-regular-svg-icons/faWifi";
import { faWifiSlash } from "@fortawesome/pro-regular-svg-icons/faWifiSlash";
import { faWifiWeak } from "@fortawesome/pro-regular-svg-icons/faWifiWeak";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    width: 300
  },

  imageSection: {
    padding: theme.spacing.md,
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
const Fitbit = (props: any) => {

  const { classes } = useStyles();

  const { token } = useSelector((store: any) => store.auth);

  const [fetching, setFetching] = useState<boolean>(false);
  const [devices, setDevices] = useState<IUserDevice[]>([])
  const { translations } = useSelector((state: any) => state.lang);
  const [onlineUsers, setOnlineUsers] = useState([])
  const [deviceData, setDeviceData] = useState([])
  const didUnmount = useRef(false);
  const [sessionInProgress, setSessionInProgress] = useState([]);
  const [baseline, setBaseline] = useState<number>(0)

  const [socketUrl, setSocketUrl] = useState(`${process.env.REACT_APP_WSS}?token=${token}`);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      /*
      useWebSocket will handle unmounting for you, but this is an example of a
      case in which you would not want it to automatically reconnect
    */
      return didUnmount.current === false;
    },
    reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    reconnectAttempts: 10,
    retryOnError: true
  });

  const search = () => {
    setFetching(true);
    UserDevicesService.listUserDevices()
        .then(setDevices)
        .catch(genericError)
        .finally(() => setFetching(false))
  }

  useEffect(() => {
    search()
  }, [])

  useEffect(() => {
    if ( !token ) return;
    setSocketUrl(`${process.env.REACT_APP_WSS}?token=${token}`)
  }, [token])

  useEffect(() => {
    if ( lastMessage !== null ) {
      try {
        const data = JSON.parse(lastMessage.data);
        if ( data.onlineUsers ) {
          setOnlineUsers(data.onlineUsers)
          for ( let i = 0; i < sessionInProgress.length; i++ ) {
            const currentDeviceID = sessionInProgress[i].device;
            const found = data.onlineUsers.some(ou => ou.device_id === currentDeviceID)
            if ( !found ) {
              setSessionInProgress([...sessionInProgress.filter(d => d.device !== currentDeviceID)])
            }
          }
          // Ottimizzare render
        }
        if ( data.heartRate ) {
          const oData = structuredClone(deviceData);
          const newData = [...oData.filter(d => d.device_id === data.source), data];
          setDeviceData(newData)
          // Aggiungere al fitbit corrispondente il valore di heartrate (device_id)
        }
        if ( data.message ) {
          if ( data.message === 'food_exposure' ) {
            setSessionInProgress([...sessionInProgress, {
              type: 'food_exposure',
              device: data.source
            }])
          }
          if ( data.message === 'stop' ) {
            setSessionInProgress([...sessionInProgress.filter(d => d.device !== data.source)])
          }

          if ( typeof data.message === 'number' ) {
            setBaseline(data.message)
          }
        }
      } catch ( e ) {
        console.error(e)
      }
      //setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage]);

  const handleClickSendMessage = useCallback((obj) => sendMessage(JSON.stringify(obj)), [])

  const connectionStatus = {
    [ReadyState.CONNECTING]: <><FontAwesomeIcon icon={faWifiWeak}
                                                beatFade
                                                color={'yellow'}/>{translations.sConnecting ?? "translations.sConnecting"}</>,
    [ReadyState.OPEN]: <><FontAwesomeIcon icon={faWifi}
                                          beat
                                          color={'teal'}/> {translations.sOpen ?? "translations.sOpen"}</>,
    [ReadyState.CLOSING]: translations.sClosing ?? "translations.sClosing",
    [ReadyState.CLOSED]: <><FontAwesomeIcon icon={faWifiSlash}
                                            color={'red'}/> {translations.sClose ?? "translations.sClose"}</>,
    [ReadyState.UNINSTANTIATED]: translations.sUninstantiated ?? "translations.sUninstantiated",
  }[readyState];


  return <>
    <Flex gap={8} mt={12}
          justify={'flex-end'}
          align={'center'}>
      <Text>{translations.serverConnect ?? "translations.serverConnect"}</Text>
      <Flex
          gap={8}
          align={'center'}>
        {connectionStatus}
      </Flex>
    </Flex>

    <Flex wrap={"wrap"} gap={16} mt={24}>
      {devices?.map((t: any, index: number) => {
        const online = onlineUsers?.find((u) => u.device_id === t.id)
        const hr = deviceData?.find(d => d.source === t.id)?.heartRate;
        return <Card withBorder radius="md" className={classes.card}
                     key={'cd-' + index}>
          <Card.Section className={classes.imageSection}>
            <Image
                src={t.device_type === 'oculus' ? '/oculus.png' : '/fitbit.png'}
                alt="Device"
                height={90} width={90}/>
            <Text fw={500}>{t.device_name}</Text>

            <div style={{ position: 'absolute', top: 3, right: 7 }}>
              {
                online ?
                    <FontAwesomeIcon icon={faWifi}
                                     beat
                                     size={'sm'}
                                     color={'teal'}/>
                    :
                    <FontAwesomeIcon icon={faWifiSlash}
                                     size={'sm'}
                                     color={'red'}/>}
            </div>
          </Card.Section>

          {t.device_type === 'oculus' ?
              <Card.Section className={classes.section}>
                <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                  {baseline}
                </Text>
                <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
                  Baseline
                </Text>

                <Text fz="sm" mt={12} mb={4}>
                  {translations.queuedConfigurations}
                </Text>

                {props.configurations
                    .filter(c => c.status === 'queue')
                    .filter(c => c.user_device?.id === t.id)
                    .slice(0, 10)
                    .map((c, index: number) =>
                        <div key={'k-' + c.id}>
                          <Group noWrap>
                            <Tooltip label={c.room}>
                              <Avatar
                                  src={c.room === 'restaurant' ? '/images/kitchen.png' : '/images/restaurant.png'}/>
                            </Tooltip>
                            <div style={{ textAlign: 'left' }}>
                              <Text
                                  fz="xs">{c.hr_max > 0 ? translations.performance_location : translations.food_location}: {
                                c.room === 'OceanMap' ? translations.ocean :
                                    (
                                        c.room === 'Bedroom' ? translations.bedroom :
                                            (
                                                c.room === 'kitchen' ? translations.kitchen :
                                                    (
                                                        c.room === 'restaurant' ? translations.ristorante :
                                                            (
                                                                c.room === 'Dining_room' ? translations.dining_room :
                                                                    translations.volcano
                                                            )
                                                    )
                                            )
                                    )
                              }</Text>
                              <Text fz="xs"
                                    c="dimmed">{translations.titlePatient}: {c.patient?.code}</Text>
                            </div>
                          </Group>
                        </div>
                    )}

              </Card.Section>
              :
              <Card.Section className={classes.section}>
                <Group spacing={30}>
                  <div>
                    <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                      {hr}
                    </Text>
                    <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }}
                          mt={3}>
                      {hr ? (translations.heartRate ?? "translations.heartRate") : '-'}
                    </Text>

                    <Text fz="sm" fw={400} sx={{ lineHeight: 1 }} mt={12}>
                      {translations.tableConnections}
                    </Text>
                    <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }}
                          mt={3}>
                      {t.connections?.map((cn: any) =>
                          <div key={'cn-' + cn.id}>{cn.device_name}</div>
                      )}
                    </Text>
                  </div>
                </Group>
              </Card.Section>
          }
        </Card>
      })}
    </Flex>


  </>
}
export default Fitbit;