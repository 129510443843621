export function esTranslations() {
    return {
        "completed": "Concluído",
        "active": "Em andamento",
        "total": "Totais",
        "creationDate": "Data de criação",
        "IAccept": "He leído y acepto los",
        "termsAndConditions": "términos y condiciones",
        "privacyPolicy": "política de privacidad",
        "aCActions": "Comportamiento",
        "aCAlert": "Eliminar esta configuración también eliminará todos los resultados relacionados.",
        "aCBreadcrumbsApps": "Todas las aplicaciones",
        "aCBreadcrumbsConfig": "Configuraciones",
        "aCCancel": "Cancelar",
        "accedi": "Iniciar sesión",
        "aCCompleted": "Completado en",
        "aCCreatedAt": "Creado en:",
        "aCDelete": "Borrar",
        "aCDeleteConf": "Confirme la configuración de eliminación por ID:",
        "aCDevice": "Dispositivo",
        "aCDownload": "Descargar CSV",
        "aCFood": "Alimento",
        "aCFoodList": "Nombre de la lista de alimentos",
        "aCfoodListT": "Lista de alimentos",
        "aCLevel": "Nivel",
        "aCLoading": "Cargando...",
        "aCNoResults": "No hay resultados",
        "aCPatient": "Código de paciente",
        "aCPatients": "Pacientes",
        "aCPatientsSearch": "Buscar paciente ...",
        "aCPaziente": "Paciente",
        "aCREC": "Recreación",
        "aCRoom": "Habitación",
        "aCSelectPH": "Filtrar por estado...",
        "aCSessionId": "ID de configuración:",
        "aCStatus": "Estado",
        "actionId": "Identificación:",
        "actionName": "Nombre:",
        "addCart": "Añadir a la cesta",
        "addPatient": "Agregar paciente",
        "addressS": "DIRECCIÓN",
        "affogato": "Affogato",
        "alertMfa": "Solo puede verificar su código después de conectar con éxito un autenticador",
        "alertUseApp": "Para comenzar a usar esta aplicación, comuníquese con nuestro soporte en",
        "alertWarning": "¡Prestar atención!",
        "allApps": "Todas las aplicaciones",
        "allPatients": "Todos los pacientes",
        "allSessionsB": "Todas las sesiones",
        "allSessionsLabel": "Agregue al menos un paciente y cree una lista de alimentos antes de crear una sesión",
        "ambitoDelCorso": "Temas del curso",
        "anguria": "Sandía",
        "answerNo": "No",
        "answerYes": "Sí",
        "applicazioni": "Aplicaciones",
        "appSubTitle": "Nuestras innovadoras herramientas de Realidad Virtual y Biosensores para profesionales de la salud mental -psicólogos, médicos, psicoterapeutas, neurocientíficos, investigadores- se actualizan constantemente.",
        "appSubTwoTitle": "Descubra nuestras aplicaciones, experiencias inmersivas y psicotecnologías para la práctica clínica, la rehabilitación, la organización, el deporte, la escuela y el bienestar.",
        "appTitle": "NUESTRAS APLICACIONES DE REALIDAD VIRTUAL",
        "arachidi": "Cacahuetes",
        "arancia": "Naranja",
        "arrosto": "Asado",
        "articoli": "Últimos artículos",
        "authRequired": "Solo después de conectar un autenticador, puede continuar con la verificación",
        "azienda": "¿Eres una empresa?",
        "baba": "Baba",
        "backHome": "Volver a la página de inicio",
        "backLogin": "Volver a la página de inicio de sesión",
        "backMfa": "Atrás",
        "banana": "Plátano",
        "barrette": "Barritas de cereales",
        "baseline": "Base",
        "becomeText": "BECOME crea soluciones tecnológicas y de formación innovadoras para la salud mental",
        "benvenuto": "Bienvenido ### para BECOME.",
        "biscotti": "Galletas",
        "bistecca": "Bistec",
        "bombolone": "Bombolone",
        "bozza": "Guardar como borrador",
        "breadcrumbFoodList": "Listas de alimentos",
        "breadcrumbPatients": "Pacientes",
        "breadcrumbsAllApps": "Todas las aplicaciones",
        "bresaola": "Bresaola",
        "bruschetta": "Bruschetta",
        "bug": "Reportar un error",
        "burger": "Hamburguesa",
        "buttonCreate": "Crear",
        "buttonEsplora": "Explorar",
        "buyNow": "Comprar ahora",
        "calzone": "Calzone",
        "cannolo": "Cannoli",
        "cannoncino": "Cannoncino",
        "caramelle": "Caramelo",
        "carote": "Zanahorias",
        "categoryS": "Categoría seleccionada:",
        "certificati": "Puede encontrar en esta página los certificados que ya ha generado y los materiales de los cursos a los que tiene derecho.",
        "certificato": "Certificado:",
        "chips": "Chips",
        "ciliege": "Cerezas",
        "cioccolato": "Chocolate",
        "clickLink": "Haga clic en el enlace para completar la autenticación",
        "coda": "Guardar y hacer cola",
        "codeField": "Código de verificación",
        "confirmPw": "confirmar Contraseña",
        "confirmPwLabel": "Tu contraseña",
        "confirmReg": "Confirmar",
        "connectAuth": "Conectar un autenticador",
        "connectAuthApp": "Conecte una aplicación de autenticador",
        "connectTitle": "Conectar un nuevo dispositivo",
        "cono": "Cono",
        "contattaci": "Contáctenos",
        "cookies": "Galletas",
        "corn_flakes": "Copos de maíz",
        "corsi": "Cursos",
        "corsiNoECM": "Cursos de capacitación asincrónica",
        "corsiTitle": "NUESTROS CURSOS ECM",
        "corsiTitleTwo": "Suscríbase a nuestro boletín para no perderse los próximos cursos para psicólogos, médicos, psicoterapeutas, neurocientíficos, investigadores y estudiantes universitarios.",
        "coscia_di_pollo": "Muslo de pollo",
        "cotoletta": "Schnitzel",
        "courseContent": "Temas del curso",
        "courseDescr": "Descripción del curso",
        "courseDetailsAuthor": "Autores:",
        "courseDetailsLastUpdate": "Actualizado el:",
        "crackers": "Galletas",
        "createAcc": "Crear una cuenta",
        "createAccount": "Crea una cuenta nueva",
        "createList": "Crear nueva lista",
        "crocchette": "Croquetas",
        "croissant": "Croissant",
        "crostata": "Tarta",
        "cucina": "Cocina",
        "bedroom": "Dormitorio",
        "cupcake": "Magdalenas",
        "currentDisabled": "Sección deshabilitada temporalmente",
        "deleteBCancel": "Cancelar",
        "deleteBDelete": "Borrar",
        "deleteConfirm": "¿Confirmar eliminación? ###",
        "deleteWarning": "Eliminar esta lista de alimentos también eliminará todas las configuraciones y resultados relacionados.",
        "descriptionBug": "Descripción",
        "dettagli": "Detalles",
        "deviceID": "El dispositivo debe existe",
        "deviceName": "Nombre:",
        "devicesMain": "Dispositivos",
        "devicesMainText": "En esta página, podrá encontrar los tokens de acceso para las aplicaciones Become.",
        "deviceTotal": "Total de dispositivos",
        "diurna": "Activar el modo diurno",
        "docTitle": "BECOME| Todos los cursos",
        "donut": "Donut",
        "durata": "Duración",
        "eLearning": "Plataforma de aprendizaje electrónico",
        "emailL": "Correo electrónico",
        "emailLabel": "Correo electrónico",
        "emailR": "Correo electrónico",
        "emailS": "Correo electrónico",
        "entra": "Acceso",
        "expired": "Contraseña caducada",
        "fetchingLoading": "Cargando...",
        "fetchingNoResults": "No hay resultados",
        "fetchLoading": "Cargando...",
        "fetchNoResults": "No hay resultados",
        "fette_biscottate": "Rusco",
        "filteR": "Filtros",
        "firstStep": "Primer paso",
        "firstStepButton": "Configuración de MFA",
        "firstStepDesc": "Configuración de MFA",
        "firstStepText": "Aumente la seguridad de su cuenta con un código MFA",
        "focaccia": "Focaccia",
        "fontDecrease": "Disminuir el tamaño de la fuente",
        "fontIncrease": "Aumenta el tamaño de la fuente",
        "food": "Alimento",
        "foodList": "Seleccione la lista de alimentos",
        "foodListCreate": "Crear lista",
        "foodListID": "La lista de alimentos debe existe",
        "foodListName": "Lista de nombres",
        "forgotPw": "¿Olvidaste tu contraseña?",
        "formaggio": "Queso",
        "fritto_misto": "Mixto frito",
        "frutti_di_bosco": "Bayas",
        "fullName": "Nombre completo",
        "gamberi": "Gambas",
        "genera": "Generar",
        "getStarted": "Empezar ahora",
        "goCourses": "Ir a cursos gratuitos",
        "gorgonzola": "Gorgonzola",
        "goToCart": "Ver toda la cesta",
        "goToDevice": "Ir a dispositivos",
        "goToLists": "Ir a listas",
        "goVerification": "Ir a la verificación",
        "hamburger": "Hamburguesas",
        "heartRate": "Ritmo cardiaco",
        "hot_dog": "Perro caliente",
        "inCart": "Ya en el carrito",
        "includeApp": "App incluida",
        "infoD": "Información",
        "insalata": "Ensalada",
        "insalata_orzo": "Ensalada de cebada",
        "insertCodeText": "Para completar el registro, ingrese el código que enviamos a su dirección de correo electrónico.",
        "insertCodeTitle": "Inserte su código de verificación",
        "insertEmail": "Ingrese su correo electrónico, recibirá un código para continuar con el reinicio.",
        "insertMfa": "Inserte el código de confirmación para concluir la configuración de MFA.",
        "insertPw": "Inserte una nueva contraseña.",
        "insertPwText": "Elija una contraseña fuerte y única para la seguridad.",
        "inThisPage": "En esta página puede ver los cursos ofrecidos por BECOME.",
        "invalidName": "Nombre inválido",
        "invalidPw": "Contraseña invalida",
        "kebab": "Kebab",
        "labelAddress": "Dirección",
        "labelBachelor": "Título",
        "labelCode": "Código",
        "labelNAME": "Nombre",
        "labelProfession": "Profesión",
        "labelProfile": "¿Desea aparecer en la página de Profesionales certificados?",
        "labelRecord": "¿Registrar resultados de Fitbit?",
        "labelRemoveCart": "Eliminar de la cesta",
        "labelRoom": "Seleccione la habitación",
        "labelState": "Región",
        "labelStatus": "Estado",
        "labelSurname": "Apellido",
        "labelWebsite": "Página web",
        "lasagne": "Lasagne",
        "lastStep": "Último paso",
        "lastStepDesc": "Código de verificación",
        "liveDemo": "Demostraciones en directo",
        "liveRegister": "Grabación en vivo",
        "loginFCPw": "Acceso",
        "loginFromMobile": "Iniciar sesión desde el móvil",
        "loginValidateEmail": "Email inválido",
        "loginWithQR": "Iniciar sesión con QRCode.",
        "macarons": "Macarons",
        "macedonia": "Macedonia",
        "manualConnection": "O agregue manualmente el siguiente código dentro de su aplicación de autenticación",
        "meringa": "Merengue",
        "mfaBackLogin": "Volver a la página de inicio de sesión",
        "mfaConfirm": "Confirma código",
        "mfaSetup": "Ha configurado con éxito MFA para su cuenta.",
        "mfaSetupText": "Ha fortalecido la seguridad de su cuenta.",
        "mfaText": "Ingrese su código MFA para completar el inicio de sesión",
        "mfaTitle": "Inserte su código MFA.",
        "mfaValidate": "El código MFA debe ser de 6 números",
        "minestrone": "Minestrone (sopa de verduras)",
        "mobileLink": "Enlace móvil para autenticación",
        "modalConnect": "Conectar otro dispositivo",
        "modalConnectButton": "Conectar",
        "modalLabelCancel": "Cancelar",
        "modalLabelConfirm": "Desactivar",
        "modalRemoveConfirm": "Haga clic para confirmar su acción",
        "modalRemoveMfa": "Está a punto de eliminar MFA de su cuenta",
        "modalTitle": "Nueva lista de alimentos",
        "modalTitleP": "Paciente nuevo",
        "more": "Más",
        "muffin": "Magdalenas",
        "myDevices": "Mis dispositivos",
        "myOrder": "Mi pedido:",
        "nameTable": "Nombre",
        "newConfig": "Nueva configuración",
        "newSession": "Nueva sesión",
        "nighiri": "Nighiri",
        "noAccount": "¿No tienes una cuenta?",
        "noCertificati": "Sin certificado",
        "noPower": "Tú no tienes poder aquí.",
        "notAuth": "No permitido",
        "notAuthText": "No tiene permiso para acceder a este contenido",
        "notFound": "Página no encontrada",
        "notFoundtext": "La dirección que seleccionó no existe o ha sido eliminada.",
        "notification": "Recibirá una notificación tan pronto como el certificado esté listo",
        "notificationText": "Nos hemos encargado de su solicitud. Recibirá una notificación en unos minutos.",
        "notificationTextTwo": "Si no lo entiende, intente recargar esta página.",
        "notturna": "Activar el modo nocturno",
        "ocean": "Océano",
        "oneDevice": "¿Tiene al menos un dispositivo?",
        "oneList": "¿Tiene al menos una lista de alimentos?",
        "onePatient": "¿Tienes al menos un paciente?",
        "orderSummary": "Resumen del pedido:",
        "paCRooms": "Habitaciones",
        "paginaprincipale": "Home",
        "panettone": "Panettone",
        "parmigiana": "Parmigiana",
        "passwordL": "Contraseña",
        "passwordLabel": "ingrese al menos 6 caracteres",
        "passwordLPH": "Tu contraseña",
        "passwordR": "Contraseña",
        "pasta": "Pastelería",
        "pasticcino_frutta": "Pastelería de frutas",
        "patatine_fritte": "Patatas fritas",
        "patientAlert": "Preferimos no almacenar los datos de su paciente en nuestros servidores para obtener seguridad y privacidad. Recuerde escribir el código del paciente en un libro/agenda física.",
        "patientID": "El paciente debe existe",
        "pere": "Peras",
        "pesce": "Pescado",
        "pesce_alla_griglia": "Pescado a la plancha",
        "pesce_spada": "Pez espada",
        "pesche": "Melocotones",
        "phoneS": "Teléfono",
        "piattaforma": "Ir a la plataforma",
        "pickOne": "Elegir uno",
        "pizza": "Pizza",
        "placeholderBachelor": "Dott*",
        "pollo_ai_ferri": "Pollo a la plancha",
        "pomodori": "Tomates",
        "praline": "Pralinés",
        "profAddress": "Sin dirección",
        "profes": "¿Eres un profesional?",
        "professionalTitle": "Desde esta sección puedes habilitar tu perfil público en la página de profesionales",
        "professione": "Profesión no indicada",
        "professionistiLabel": "Buscar en",
        "professionistiPlaceholder": "Buscar por nombre, dirección o región...",
        "professionistiTitle": "Profesionales certificados",
        "profWebsite": "Página personal",
        "pwLabel": "Tu contraseña",
        "pwNotMatch": "Las contraseñas no coinciden",
        "queuedConfigurations": "Configuraciones en la cola",
        "recordingStart": "Empezar a grabar",
        "recordingStop": "Para de grabar",
        "registerR": "Iniciar sesión",
        "registrati": "Iniciar sesión",
        "rememberMeL": "Acuérdate de mí",
        "removeCart": "Eliminar",
        "removeMfa": "Eliminar MFA",
        "removeMfaInstruction": "Si necesita eliminar el MFA, inserte su contraseña y luego presione el botón a continuación",
        "reqPwLabelFour": "Incluye símbolo especial",
        "kitchen": "Cucina",
        "reqPwLabelOne": "Incluye número",
        "reqPwLabelThree": "Incluye letra mayúscula",
        "reqPwLabelTwo": "Incluye letra minúscula",
        "resetFilter": "Borrar filtros",
        "resetPassword": "Compruebe su buzón",
        "resetPw": "Restablecer la contraseña",
        "ricerca": "Búsqueda",
        "ricotta": "Ricotta",
        "riso": "Arroz",
        "ristorante": "Restaurante",
        "sacher": "Sacher",
        "saint_honoré": "Saint Honoré",
        "salame": "Salami",
        "salatini": "Salatini",
        "salsiccia": "Salchichón",
        "SAVE": "Ahorrar",
        "scanQR": "O escanear el código QR",
        "scarica": "Descargar",
        "sClose": "Cerrado",
        "sClosing": "Clausura",
        "sConnecting": "Intentando conectarse",
        "searchByName": "Buscar por nombre",
        "searchPatient": "Buscar por código",
        "secondStep": "Segundo paso",
        "selectDevice": "Seleccione el dispositivo",
        "selectLanguage": "Seleccione el idioma",
        "selectPatient": "Seleccionar paciente",
        "selectRoom": "Selecciona una habitación",
        "selectTheme": "Activar",
        "sendEmail": "Escríbanos un correo electrónico, se le contactará dentro de las 24 horas.",
        "sendReport": "Enviar reporte",
        "serverConnect": "Conexión al servidor:",
        "sessioni": "Sesiones totales",
        "setUpDevice": "Configurar un nuevo dispositivo",
        "setupMfaTitle": "Configure su código MFA.",
        "sezione": "Sección de certificados y materiales",
        "sidebarApp": "Aplicación",
        "sidebarCourses": "Cursos",
        "sidebarDevices": "Dispositivos",
        "sidebarHome": "Hogar",
        "sidebarMaterials": "Materiales",
        "sidebarProfessionals": "Página de profesionales",
        "sidebarSecurity": "Seguridad",
        "sidebarSupport": "Apoyo",
        "signUp": "Inscribirse",
        "simmenthal": "Simmenthal",
        "snack": "Merienda",
        "snack_cioccolato": "Snack Chocolate",
        "soluzioni": "Soluciones",
        "sOpen": "Abierto",
        "spiedini": "Pinchos",
        "stanza": "Seleccionar habitación válida",
        "statusStatus": "Seleccionar estado válido",
        "stinco": "Vástago",
        "subtotale": "Subtotal",
        "success": "Éxito",
        "sUninstantiated": "Desinstenciado",
        "sushi": "Sushi",
        "tableActions": "Comportamiento",
        "tableCode": "Código",
        "tableConnect": "Conectar",
        "tableConnections": "Dispositivos conectados",
        "tableCreateAt": "Creado en",
        "tableDeviceName": "Nombre del dispositivo",
        "tableDeviceType": "Tipo",
        "tableOneTime": "Contraseña de una sola vez",
        "tableQR": "Código QR",
        "taco": "Taco",
        "tartare_tonno": "Tartar de Atún",
        "tax": "+ IVA",
        "taxText": "Impuestos",
        "taxToPay": "Calculados en el momento de la compra",
        "themeDark": "modo nocturno",
        "themeLight": "modo diurno",
        "titleActions": "Comportamiento",
        "titleBug": "Título",
        "titleDevice": "Dispositivo",
        "titleFoodList": "Lista de alimentos",
        "titleFoodList__1": "Lista de alimentos",
        "titlePatient": "Paciente",
        "titleRoom": "Habitación",
        "toOrder": "Proceder al pedido",
        "topic": "Tema",
        "torta_margherita": "Tarta Daisy",
        "torta_paradiso": "Tarta Paraíso",
        "tortilla": "Tortilla",
        "totalConfigurationsTable": "Configuraciones totales",
        "totalFoodTable": "Comida total",
        "totalPatients": "Total de pacientes",
        "totalPatientsLabel": "¿Necesitas más pacientes? Actualice su plan actual",
        "totatListsFood": "Listas de alimentos personalizados totales",
        "totatListsFoodLabel": "¿Necesita más listas de alimentos? Actualice su plan actual",
        "uovo_e_bacon": "Huevo y beicon",
        "upgradeLabelConfig": "¿Necesita más configuraciones? Actualice su plan actual",
        "upgradeLabelDevice": "¿Necesitas más dispositivos? Actualice su plan actual",
        "upgradePlan": "Plan de actualización",
        "uva": "Uvas",
        "validate": "Email inválido",
        "validateCode": "El código de verificación debe tener 6 letras",
        "validateEmail": "Email inválido",
        "validateName": "El nombre debe tener al menos 3 letras",
        "validatePw": "Contraseña invalida",
        "variation": "Variación",
        "verdure_grigliate": "Verduras a la plancha",
        "verifyMfa": "Verifique su MFA.",
        "videoPlayer": "Vista previa del curso",
        "viewCart": "Ver cesta de la compra",
        "volcano": "Volcán",
        "warningAlert": "Para autenticarse en un dispositivo, ingrese un token ### One Time Password ### (si esto está \"disponible\"). ### Si se ha quedado sin tokens disponibles y necesita autenticarse en un dispositivo, comuníquese con nuestro",
        "warningAlertSupport": "apoyo",
        "warningText": "Una vez que se ha creado una lista, ya no se puede modificar, de lo contrario invalidaría los resultados ya almacenados.",
        "welcome": "¡Bienvenido!",
        "whatYouFind": "Aquí puede encontrar cursos de acceso abierto",
        "whatYouWLearn": "Lo que aprenderá",
        "workS": "Horas Laborales",
        "yogurt": "Yogur",
        "youCart": "Su cesta de la compra",
        "a_cake": "A. Pastel",
        "a_pizza": "A. Pizza",
        "a_chips": "A. Patatas Fritas",
        "a_nuts": "A. Cacahuetes",
        "a_cookies": "A. Galletas",
        "a_ice_cream": "A. Helado",
        "a_chocolate": "A. Chocolate",
        "a_bread": "A. Pan",
        "a_donuts": "A. Donuts",
        "a_brownies": "A. Brownies",
        "a_spaghetti": "A. Espagueti",
        "a_rice": "A. Arroz",
        "a_fruit": "A. Fruta",
        "a_salad": "A. Ensalada",
        "a_yogurt": "A. Yogur",
        "a_pancakes": "A. Panqueques",
        "a_lean_meat": "A. Cortar",
        "a_fried_chicken": "A. Pollo frito",
        "a_peanut_butter": "A. Mantequilla de maní",
        "a_snacks": "A. Bocadillos",
        "a_popcorn": "A. Palomitas de maíz",
        "a_pastries": "A. Pegar",
        "a_cereal": "A. Cereales",
        "a_bacon": "A. Tocino",
        "a_steak": "A. Filete",
        "a_baked_potato": "A. Patatas",
        "a_cupcakes": "A. Pastelitos",
        "b_brigadeiro": "B. Brigadeiro",
        "b_joelho": "B. Joelho",
        "b_orange_juice": "B. Orange Juice",
        "b_soft_drink": "B. Soft Drink",
        "b_spaghetti_bolognese": "B. Spaghetti Bolognese",
        "b_açai_bowl": "B. Açai Bowl",
        "b_paçoca": "B. Paçoca",
        "b_pão_de_queijo": "B. Pão de Queijo",
        "b_oreo": "B. Oreo",
        "b_beef_stroganoff": "B. Beef Stroganoff",
        "b_empada": "B. Empada",
        "b_coxinha_de_galinha": "B. Coxinha de Galinha",
        "difficulty": "Tiempo base de grabación (en segundos)",
        "hrMin": "Porcentaje de cambio",
        "hrMax": "Duración de la sesión (en segundos)"
    }
}