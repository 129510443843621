import axios, { AxiosInstance } from "axios";
import { Interceptor } from "../pages/auth/interceptor";


export default class AuthService {

    static register = async (data: {
        name: string,
        email: string,
        password: string,
        order_of_psychologists: string,
        order_number: string
    }): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/register`;
        return axios.post(url, data).then((res: any) => {
            return res.data;
        });

    }

    static initChallenge = async (data: { email: string }): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/initChallenge`;
        return axios.post(url, data).then((res: any) => {
            return res.data;
        });
    }

    static checkAgainstCognito = async (data: { email: string, password: string }): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/checkAgainstCognito`;
        return axios.post(url, data).then((res: any) => {
            return res.data;
        });

    }

    static verifyChallenge = async (data: {
        challenge_id: string,
        challenge: string,
        email: string,
        remember_me: boolean,
    }): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/verifyChallenge`;
        return axios.post(url, data).then((res: any) => {
            return res.data;
        });

    }

    static checkMfa = async (data: { code: string, remember_me: boolean, }): Promise<any> => {
        const url = `${process.env["REACT_APP_URL"]}/api/auth/checkMfa`;
        return await Interceptor().then((ax: AxiosInstance) => {
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }

    static setupMfa = async (): Promise<any> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/setupMfa`;
            return ax.post(url).then((res: any) => {
                return res.data;
            });
        });
    }

    static confirmMfa = async (data: { code: string }): Promise<any> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/confirmMfa`;
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }
    static verify = async (data: { code: string, email: string }): Promise<any> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/verify`;
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }

    static forceChangePw = async (data: {
        key: string,
        value: string,
        email: string,
        password: string
    }): Promise<any> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/forceChangePassword`;
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }

    static disableMfa = async (data: {
        challenge_id: string,
        challenge: string,
        email: string,
    }): Promise<null> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/disableMfa`;
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }

    static logout = async (): Promise<null> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/logout`;
            return ax.post(url).then((res: any) => {
                return res.data;
            });
        });
    }

    static askResetPassword = async (data: any): Promise<null> => {
        return await Interceptor().then((ax: AxiosInstance) => {
            const url = `${process.env["REACT_APP_URL"]}/api/auth/askResetPassword`;
            return ax.post(url, data).then((res: any) => {
                return res.data;
            });
        });
    }

}