export function brTranslations() {
  return {
    "and": " e ",
    "IAccept": "Eu li e aceito os",
    "termsAndConditions": "termos e condições",
    "privacyPolicy": "política de privacidade",
    "aCActions": "Ações",
    "aCAlert": "A exclusão dessa sessão também excluirá todos os resultados relacionados.",
    "aCBreadcrumbsApps": "Todos os aplicativos",
    "aCBreadcrumbsConfig": "Sessões",
    "aCCancel": "Cancelar",
    "accedi": "Fazer login",
    "aCCompleted": "Concluída em",
    "aCCreatedAt": "Criado em:",
    "aCDelete": "Excluir",
    "aCDeleteConf": "Confirmar o cancelamento da sessão via id:",
    "aCDevice": "Dispositivo",
    "aCDownload": "Baixar CSV",
    "aCFood": "Alimentos",
    "aCFoodList": "Nome da lista de alimentos",
    "aCfoodListT": "Lista de alimentos",
    "aCLevel": "Nível",
    "aCLoading": "Carregando...",
    "aCNoResults": "Nenhum resultado",
    "aCPatient": "Código do paciente",
    "aCPatients": "Pacientes",
    "aCPatientsSearch": "Pesquisar paciente...",
    "aCPaziente": "Paciente",
    "aCREC": "REG",
    "aCRoom": "Sala",
    "aCSelectPH": "Filtrar por status...",
    "aCSessionId": "ID da sessão:",
    "aCStatus": "Status",
    "actionId": "Id:",
    "actionName": "Nome:",
    "addCart": "Adicionar ao carrinho",
    "addPatient": "Adicionar paciente",
    "addressS": "Endereço",
    "affogato": "Afogado",
    "alertMfa": "Você só pode verificar seu código depois de conectar um Authenticator com sucesso",
    "alertUseApp": "Para começar a usar este aplicativo, entre em contato com nosso suporte em",
    "alertWarning": "Atenção!",
    "allApps": "Todos os aplicativos",
    "allPatients": "Todos os pacientes",
    "allSessionsB": "Todas as sessões",
    "allSessionsLabel": "Adicione pelo menos um paciente e crie uma lista de alimentos antes de criar uma sessão",
    "ambitoDelCorso": "Tópicos do curso",
    "anguria": "Melancia",
    "answerNo": "Não",
    "answerYes": "Sim",
    "applicazioni": "Aplicativo",
    "appSubTitle": "Nossas ferramentas inovadoras de realidade virtual e biossensor para profissionais de saúde mental - psicólogos, médicos, psicoterapeutas, neurocientistas, pesquisadores - são constantemente atualizadas.",
    "appSubTwoTitle": "Descubra nossos aplicativos, experiências imersivas e psicotecnologias para práticas clínicas, de reabilitação, organizacionais, esportivas, escolares e de bem-estar.",
    "appTitle": "NOSSOS APLICATIVOS DE REALIDADE VIRTUAL",
    "arachidi": "Amendoim",
    "arancia": "Laranja",
    "arrosto": "Assado",
    "articoli": "Artigos mais recentes",
    "authRequired": "Somente depois de conectar um Authenticator você poderá prosseguir com a Verificação",
    "azienda": "Você é uma empresa?",
    "baba": "Baba",
    "backHome": "Voltar para a página inicial",
    "backLogin": "Voltar para o login",
    "backMfa": "Voltar",
    "banana": "Banana",
    "barrette": "Barras de cereais",
    "baseline": "Linha de base",
    "becomeText": "A BECOME cria soluções inovadoras de tecnologia e treinamento para a saúde mental",
    "benvenuto": "Bem-vindo* ### à BECOME.",
    "biscotti": "Biscoitos",
    "bistecca": "Bife",
    "bombolone": "Bombolone",
    "bozza": "Salvar como rascunho",
    "breadcrumbFoodList": "Listas de alimentos",
    "breadcrumbPatients": "Pacientes",
    "breadcrumbsAllApps": "Todos os aplicativos",
    "bresaola": "Bresaola",
    "bruschetta": "Bruschetta",
    "bug": "Relatar um erro",
    "burger": "Hambúrguer",
    "buttonCreate": "Criar",
    "buttonEsplora": "Explorar",
    "buyNow": "Compre agora",
    "calzone": "Calzone",
    "cannolo": "Cannoli",
    "cannoncino": "Cannoncino",
    "caramelle": "Doces",
    "carote": "Cenouras",
    "categoryS": "Categoria selecionada:",
    "certificati": "Certificados:",
    "certificato": "Certificado:",
    "chips": "Batatas fritas",
    "ciliege": "Cerejas",
    "cioccolato": "Chocolate",
    "clickLink": "Clique no link para concluir a autenticação",
    "coda": "Salvar e colocar na fila",
    "codeField": "Código de confirmação",
    "confirmPw": "Confirme a senha",
    "confirmPwLabel": "Sua senha",
    "confirmReg": "Confirmar",
    "connectAuth": "Conectar um autenticador",
    "connectAuthApp": "Conectar um aplicativo autenticador",
    "connectTitle": "Conectar um novo dispositivo",
    "cono": "Cone",
    "contattaci": "Entre em contato conosco",
    "cookies": "Cookies",
    "corn_flakes": "Flocos de milho",
    "corsi": "Cursos de treinamento",
    "corsiNoECM": "Cursos de treinamento assíncronos sem CME",
    "corsiTitle": "NOSSOS CURSOS DE ECM",
    "corsiTitleTwo": "Assine nosso boletim informativo para não perder os próximos cursos para psicólogos, médicos, psicoterapeutas, neurocientistas, pesquisadores e estudantes universitários.",
    "coscia_di_pollo": "Coxa de frango",
    "cotoletta": "Schnitzel",
    "courseContent": "Tópicos do curso",
    "courseDescr": "Descrição do curso",
    "courseDetailsAuthor": "Autores:",
    "courseDetailsLastUpdate": "Atualizado em:",
    "crackers": "Crackers",
    "createAcc": "Criar uma conta",
    "createAccount": "Criar uma conta gratuita",
    "createList": "Criar nova lista",
    "crocchette": "Croquetes",
    "croissant": "Croissants",
    "crostata": "Tortas",
    "cucina": "Cozinha",
    "bedroom": "Dormitorio",
    "cupcake": "Cupcakes",
    "currentDisabled": "Seção temporariamente desativada",
    "deleteBCancel": "Cancelar",
    "deleteBDelete": "Excluir",
    "deleteConfirm": "Confirmar o cancelamento? ###",
    "deleteWarning": "Ao excluir a lista de alimentos, você também excluirá todas as configurações e seus resultados.",
    "descriptionBug": "Descrição",
    "dettagli": "Detalhes",
    "deviceID": "Deve haver um dispositivo",
    "deviceName": "Nome:",
    "devicesMain": "Dispositivos",
    "devicesMainText": "Nessa página, você pode encontrar os tokens de acesso ao aplicativo Become.",
    "deviceTotal": "Dispositivos",
    "diurna": "Ativar o modo diurno",
    "docTitle": "BECOME | Todos os cursos",
    "donut": "Donut",
    "durata": "Duração",
    "eLearning": "Plataforma de e-learning | CME",
    "emailL": "E-mail",
    "emailLabel": "E-mail",
    "emailR": "E-mail",
    "emailS": "E-mail",
    "entra": "Login",
    "expired": "Senha expirada",
    "fetchingLoading": "Carregando...",
    "fetchingNoResults": "Nenhum resultado",
    "fetchLoading": "Carregando...",
    "fetchNoResults": "Nenhum resultado",
    "fette_biscottate": "Biscoitos",
    "filteR": "Filtros",
    "firstStep": "Primeira etapa",
    "firstStepButton": "Definir MFA",
    "firstStepDesc": "Definir a MFA",
    "firstStepText": "Aumente a segurança de sua conta com a autenticação multifator",
    "focaccia": "Focaccia",
    "fontDecrease": "Diminuir o tamanho da fonte",
    "fontIncrease": "Aumentar o tamanho da fonte",
    "food": "Alimentos",
    "foodList": "Selecionar lista de alimentos",
    "foodListCreate": "Criar lista",
    "foodListID": "Deve haver uma lista de alimentos",
    "foodListName": "Nome da lista",
    "forgotPw": "Esqueceu sua senha?",
    "formaggio": "Queijo",
    "fritto_misto": "Misto frito",
    "frutti_di_bosco": "Bagas",
    "fullName": "Nome completo",
    "gamberi": "Camarão",
    "genera": "Gerar",
    "getStarted": "Começar agora",
    "goCourses": "Ir para cursos gratuitos",
    "gorgonzola": "Gorgonzola",
    "goToCart": "Ver todo o carrinho de compras",
    "goToDevice": "Ir para dispositivos",
    "goToLists": "Ir para listas",
    "goVerification": "Ir para a verificação",
    "hamburger": "Hambúrguer",
    "heartRate": "Batimento cardíaco",
    "hot_dog": "Cachorro-quente",
    "inCart": "Já está no carrinho",
    "includeApp": "Aplicativo incluído",
    "infoD": "Informações",
    "insalata": "Salada",
    "insalata_orzo": "Salada de cevada",
    "insertCodeText": "Para concluir o registro, digite o código que enviamos para o seu endereço de e-mail.",
    "insertCodeTitle": "Digite o código de confirmação",
    "insertEmail": "Digite seu e-mail e você receberá um código para prosseguir com a redefinição",
    "insertMfa": "Digite o código de confirmação para concluir a configuração do MFA.",
    "insertPw": "Digite uma nova senha.",
    "insertPwText": "Escolha uma senha complexa e exclusiva para sua segurança.",
    "inThisPage": "Nesta página, você pode ver os cursos oferecidos pela BECOME.",
    "invalidName": "Nome inválido",
    "invalidPw": "Senha inválida",
    "kebab": "Kebab",
    "labelAddress": "Endereço",
    "labelBachelor": "Título",
    "labelCode": "Código",
    "labelNAME": "Nome Social",
    "labelProfession": "Profissão",
    "labelProfile": "Você gostaria de aparecer na página de Profissionais Certificados?",
    "labelRecord": "Registrar resultados do Fitbit?",
    "labelRemoveCart": "Remover do carrinho",
    "labelRoom": "Selecione o quarto",
    "labelState": "Região",
    "labelStatus": "Estado",
    "labelSurname": "Sobrenome",
    "labelWebsite": "Site",
    "lasagne": "Lasanha",
    "lastStep": "Último estágio",
    "lastStepDesc": "Código de verificação",
    "liveDemo": "Demonstração ao vivo",
    "liveRegister": "Registro ao vivo",
    "loginFCPw": "Login",
    "loginFromMobile": "Login pelo celular",
    "loginValidateEmail": "Endereço de e-mail inválido",
    "loginWithQR": "Entrar em contato com QRCODE.",
    "macarons": "Macarons",
    "macedonia": "Macedônia",
    "manualConnection": "Ou adicione manualmente o seguinte código em seu aplicativo de autenticação",
    "meringa": "Merengue",
    "mfaBackLogin": "Voltar ao login",
    "mfaConfirm": "Confirmar código",
    "mfaSetup": "Você configurou com sucesso a autenticação de dois fatores (MFA) em sua conta.",
    "mfaSetupText": "Você reforçou a segurança de sua conta.",
    "mfaText": "Digite seu código de MFA para concluir seu login.",
    "mfaTitle": "Digite seu código MFA.",
    "mfaValidate": "O código MFA deve conter 6 números",
    "minestrone": "Minestrone",
    "mobileLink": "Link para dispositivos móveis",
    "modalConnect": "Conectar outro dispositivo",
    "modalConnectButton": "Conectar",
    "modalLabelCancel": "Cancelar",
    "modalLabelConfirm": "Desativar",
    "modalRemoveConfirm": "Clique para confirmar sua escolha",
    "modalRemoveMfa": "Você está prestes a remover a autenticação de dois fatores (MFA) de sua conta.",
    "modalTitle": "Nova lista de alimentos",
    "modalTitleP": "Novo paciente",
    "more": "Mais",
    "muffin": "Muffins",
    "myDevices": "Meus dispositivos",
    "myOrder": "Meu pedido",
    "nameTable": "Nome do paciente",
    "newConfig": "Nova configuração",
    "newSession": "Nova sessão",
    "nighiri": "Nighiri",
    "noAccount": "Não tem uma conta?",
    "noCertificati": "Sem certificado",
    "noPower": "Você não tem autoridade aqui.",
    "notAuth": "Não autorizado",
    "notAuthText": "Você não tem permissão para acessar este conteúdo",
    "notFound": "Página não encontrada",
    "notFoundtext": "O endereço que você selecionou não existe ou foi removido.",
    "notification": "Você será notificado assim que o certificado estiver pronto",
    "notificationText": "Nós cuidamos de sua solicitação. Você será notificado em alguns minutos.",
    "notificationTextTwo": "Se ela não chegar, tente recarregar esta página.",
    "notturna": "Ativar o modo noturno",
    "ocean": "Oceano",
    "oneDevice": "Você tem pelo menos um dispositivo?",
    "oneList": "Você tem pelo menos uma lista de alimentos?",
    "onePatient": "Você tem pelo menos um paciente?",
    "orderSummary": "Resumo do pedido:",
    "pACRooms": "Quartos",
    "paginaprincipale": "Casa",
    "panettone": "Panetone",
    "parmigiana": "Parmigiana",
    "passwordL": "Senha",
    "passwordLabel": "Por favor, digite pelo menos 6 caracteres",
    "passwordLPH": "Sua senha",
    "passwordR": "Senha",
    "pasta": "Massa",
    "pasticcino_frutta": "Pastelaria de frutas",
    "patatine_fritte": "Batatas fritas",
    "patientAlert": "Por motivos de segurança e privacidade, preferimos não armazenar os dados dos pacientes em nossos servidores. Lembre-se de escrever o código do paciente em um livro/agenda física.",
    "patientID": "Deve haver um paciente",
    "pere": "Peras",
    "pesce": "Peixe",
    "pesce_alla_griglia": "Peixe grelhado",
    "pesce_spada": "Espadarte",
    "pesche": "Pêssegos",
    "phoneS": "Telefone",
    "piattaforma": "Ir para a plataforma",
    "pickOne": "Selecione um dispositivo",
    "pizza": "Pizza",
    "placeholderBachelor": "Dr*",
    "pollo_ai_ferri": "Frango grelhado",
    "pomodori": "Tomates",
    "praline": "Pralinés",
    "profAddress": "Nenhum endereço inserido",
    "profes": "Você é um profissional?",
    "professionalTitle": "Nesta seção, você pode ativar seu perfil público na página de profissionais",
    "professione": "Profissão não indicada",
    "professionistiLabel": "Pesquisar",
    "professionistiPlaceholder": "Pesquise por nome, endereço ou região...",
    "professionistiTitle": "Profissionais certificados",
    "profWebsite": "Site pessoal",
    "pwLabel": "Sua senha",
    "pwNotMatch": "As senhas não correspondem",
    "queuedConfigurations": "Sessões em fila",
    "recordingStart": "Iniciar gravação",
    "recordingStop": "Parar a gravação",
    "registerR": "Registrar",
    "registrati": "Registrar",
    "rememberMeL": "Lembrar de mim",
    "removeCart": "Remover",
    "removeMfa": "Remover MFA",
    "removeMfaInstruction": "Se você quiser remover a autenticação de dois fatores (MFA), digite sua senha e pressione o botão abaixo",
    "reqPwLabelFour": "Inclui caracteres especiais",
    "reqPwLabelOne": "Inclui números",
    "reqPwLabelThree": "Inclui letras maiúsculas",
    "reqPwLabelTwo": "Incluir letras minúsculas",
    "resetFilter": "Limpar filtros",
    "resetPassword": "Verificar sua caixa postal",
    "resetPw": "Redefinir senha",
    "ricerca": "Pesquisar",
    "ricotta": "Ricota",
    "riso": "Arroz",
    "ristorante": "Restaurante",
    "sacher": "Sacher",
    "saint_honoré": "Saint Honoré",
    "salame": "Salame",
    "salatini": "Salatini",
    "salsiccia": "Salsichas",
    "SAVE": "Salve",
    "scanQR": "Ou escaneie o código QR",
    "scarica": "Baixar",
    "sClose": "Desconectado",
    "sClosing": "Desconectado",
    "sConnecting": "Tentando se conectar",
    "searchByName": "Pesquisar por nome",
    "searchPatient": "Pesquisar por código de paciente",
    "secondStep": "Segunda etapa",
    "selectDevice": "Selecionar um dispositivo",
    "selectLanguage": "Selecionar o idioma",
    "selectPatient": "Selecionar um paciente",
    "selectRoom": "Selecione um quarto",
    "selectTheme": "Ativar o modo",
    "sendEmail": "Escreva-nos um e-mail e entraremos em contato com você em 24 horas.",
    "sendReport": "Enviar relatório",
    "serverConnect": "Conexão do servidor:",
    "sessioni": "Sessões",
    "setUpDevice": "Configurar um novo dispositivo",
    "setupMfaTitle": "Configure seu código MFA.",
    "sezione": "Seção de certificados e materiais",
    "sidebarApp": "Aplicativo",
    "sidebarCourses": "Cursos",
    "sidebarDevices": "Dispositivos",
    "sidebarHome": "Início",
    "sidebarMaterials": "Materiais",
    "sidebarProfessionals": "Página de profissionais",
    "sidebarSecurity": "Segurança",
    "sidebarSupport": "Suporte ao usuário",
    "signUp": "Registro",
    "simmenthal": "Simmenthal",
    "snack": "Lanche",
    "snack_cioccolato": "Snack de chocolate",
    "soluzioni": "Soluções",
    "sOpen": "Conectados",
    "spiedini": "Espetos",
    "dining_room": "Sala de jantar",
    "stanza": "Selecione uma sala válida",
    "statusStatus": "Selecione um estado válido",
    "stinco": "Canela",
    "subtotale": "Subtotal",
    "success": "Bem-sucedido",
    "sUninstantiated": "Não instanciado",
    "sushi": "Sushi",
    "tableActions": "Ações",
    "tableCode": "Código",
    "tableConnect": "Conectar",
    "tableConnections": "Dispositivos conectados",
    "tableCreateAt": "Criado em",
    "tableDeviceName": "Nome do dispositivo",
    "tableDeviceType": "Tipo de senha",
    "tableOneTime": "Senha de uso único",
    "tableQR": "Código QR",
    "taco": "Taco",
    "tartare_tonno": "Tartare de atum",
    "tax": "+ IVA",
    "taxText": "Impostos",
    "taxToPay": "Calculado no checkout",
    "themeDark": "noite",
    "themeLight": "diurno",
    "titleActions": "Ações",
    "titleBug": "Título",
    "titleDevice": "Dispositivo",
    "titleFoodList": "Lista de alimentos",
    "titlePatient": "Paciente",
    "titleProfessional": "Página do profissional",
    "titleRoom": "Sala",
    "toOrder": "Prosseguir para o pedido",
    "topic": "Tópico",
    "torta_margherita": "Bolo Daisy",
    "torta_paradiso": "Bolo Paraíso",
    "tortilla": "Tortilha",
    "totalConfigurationsTable": "Número de configurações",
    "totalFoodTable": "Número de alimentos",
    "totalPatients": "Pacientes",
    "totalPatientsLabel": "Precisa de mais pacientes? Faça o upgrade de seu plano atual",
    "totatListsFood": "Listas de alimentos",
    "totatListsFoodLabel": "Precisa de mais listas de alimentos? Atualize seu plano atual",
    "uovo_e_bacon": "Ovos e bacon",
    "upgradeLabelConfig": "Precisa de mais configurações? Faça upgrade de seu plano atual",
    "upgradeLabelDevice": "Precisa de mais dispositivos? Faça upgrade do plano atual",
    "upgradePlan": "Plano de upgrade",
    "uva": "Uva",
    "validate": "E-mail inválido",
    "validateCode": "O código de verificação deve ser composto por 6 letras",
    "validateEmail": "E-mail inválido",
    "validateName": "O nome deve ter pelo menos 3 letras",
    "validatePw": "Senha inválida",
    "variation": "Variação",
    "verdure_grigliate": "Vegetais grelhados",
    "verifyMfa": "Verifique seu código MFA.",
    "videoPlayer": "Prévia do curso",
    "viewCart": "Ver carrinho",
    "volcano": "Vulcano",
    "warningAlert": "Para se autenticar em um dispositivo, digite a ### One Time Password ### de um token (se ele estiver \"disponível\"). ### Se todos os tokens disponíveis tiverem acabado e você precisar se autenticar em um dispositivo, entre em contato com o nosso",
    "warningAlertSupport": "suporte",
    "warningText": "Depois que a lista tiver sido criada, não será mais possível modificá-la, pois isso invalidaria os resultados já armazenados.",
    "welcome": "Bem-vindo!",
    "whatYouFind": "Aqui você pode encontrar cursos de acesso livre e revisar os cursos de CME dos quais participou gratuitamente.",
    "whatYouWLearn": "O que você aprenderá",
    "workS": "Horário de trabalho",
    "yogurt": "Iogurte",
    "youCart": "Seu carrinho de compras",
    "a_cake": "A. Bolo",
    "a_pizza": "A.Pizza",
    "a_chips": "A. Chips",
    "a_nuts": "A. Peanuts",
    "a_cookies": "A. Biscoitos",
    "a_ice_cream": "A. Gelado",
    "a_chocolate": "A.Chocolate",
    "a_bread": "A. Pão",
    "a_donuts": "A. Donuts",
    "a_brownies": "A. Brownies",
    "a_spaghetti": "Ravioli",
    "a_rice": "A. Arroz",
    "a_fruit": "A. Fruta",
    "a_salad": "A. Salada",
    "a_yogurt": "A. Iogurte",
    "a_pancakes": "A. Panquecas",
    "a_lean_meat": "A. Fatiado",
    "a_fried_chicken": "A. Frango frito",
    "a_peanut_butter": "A. Manteiga de amendoim",
    "a_snacks": "A. Lanches",
    "a_popcorn": "A. Pipocas",
    "a_pastries": "A. Pastéis doces",
    "buffet": "Ativar buffet",
    "a_cereal": "A. Cereais",
    "a_bacon": "A.Bacon",
    "a_steak": "A. Bife",
    "a_baked_potato": "A. Batatas",
    "a_cupcakes": "A.Bolinhos",
    "b_brigadeiro": "B. Brigadeiro",
    "b_joelho": "B. Joelho",
    "b_orange_juice": "B. Orange Juice",
    "b_soft_drink": "B. Soft Drink",
    "b_spaghetti_bolognese": "B. Rigattoni",
    "b_açai_bowl": "B. Açai Bowl",
    "b_paçoca": "B. Paçoca",
    "b_pão_de_queijo": "B. Pão de Queijo",
    "b_oreo": "B. Oreo",
    "b_beef_stroganoff": "B. Beef Stroganoff",
    "b_empada": "B. Empada",
    "b_coxinha_de_galinha": "B. Coxinha de Galinha",
    "difficulty": "Tempo de registro da linha de base (em segundos)",
    "hrMin": "Alteração percentual",
    "hrMax": "Duração da sessão (em segundos)"
  }
}