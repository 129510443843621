export function itTranslations() {
  return {
    "completed": "Completate",
    "active": "In corso",
    "total": "Totali",
    "creationDate": "Data di creazione",
    "and": " e ",
    "IAccept": "Ho letto e accetto",
    "termsAndConditions": "i termini e le condizioni",
    "privacyPolicy": "l'informativa privacy",
    "resetPassword": "Controlla la tua casella di posta elettronica",
    "success": "Successo",
    "articoli": "Ultimi articoli",
    "benvenuto": "Benvenut* ### in BECOME.",
    "sezione": "Sezione certificati e materiali",
    "certificati": "Certificati:",
    "notification": "Ti arriverà una notifica appena il certificato sarà pronto",
    "notificationText": "Abbiamo preso in carico la tua richiesta. Ti arriverà una notifica tra pochi minuti.",
    "certificato": "Certificato:",
    "scarica": "Scarica",
    "genera": "Genera",
    "notificationTextTwo": "Se non dovesse arrivarti, prova a ricaricare questa pagina.",
    "notAuth": "Non autorizzato",
    "notAuthText": "Non hai i permessi per accedere a questo contenuto",
    "order_of_psychologists": "Ordine degli Psicologi",
    "order_number": "Numero di iscrizione all'ordine (opzionale)",
    "noPower": "Non hai alcun potere qui.",
    "notFound": "Pagina non trovata",
    "notFoundtext": "L'indirizzo che hai selezionato non esiste o è stato rimosso.",
    "backHome": "Torna alla home",
    "contattaci": "Contattaci",
    "sendEmail": "Scrivici un email, sarai ricontattato entro 24 ore.",
    "bug": "Segnala un bug",
    "titleBug": "Titolo",
    "descriptionBug": "Descrizione",
    "sendReport": "Invia segnalazione",
    "createAccount": "Crea un account gratuito",
    "invalidName": "Nome non valido",
    "invalidPw": "Password non valida",
    "pwNotMatch": "Le password non corrispondono",
    "createAcc": "Crea un account",
    "fullName": "Nome completo",
    "emailR": "E-mail",
    "passwordR": "Password",
    "passwordLabel": "Inserisci almeno 6 caratteri",
    "confirmPw": "Conferma la password",
    "registerR": "Registrati",
    "backLogin": "Torna al login",
    "mfaValidate": "Il codice MFA deve contenere 6 numeri",
    "mfaTitle": "Inserisci il tuo codice MFA.",
    "mfaText": "Inserisci il tuo codice MFA per completare il login",
    "mfaBackLogin": "Torna al login",
    "mfaConfirm": "Conferma codice",
    "loginValidateEmail": "Indirizzo email non valido",
    "welcome": "Benvenut*!",
    "noAccount": "Non hai un account?",
    "signUp": "Registrati",
    "emailL": "E-mail",
    "passwordL": "Password",
    "passwordLPH": "La tua password",
    "rememberMeL": "Ricordami",
    "entra": "Login",
    "expired": "Password scaduta",
    "forgotPw": "Hai dimenticato la password?",
    "validate": "Email non valida",
    "insertEmail": "Inserisci la tua email, riceverai un codice per procedere al reset",
    "resetPw": "Reimposta la password",
    "emailLabel": "E-mail",
    "reqPwLabelOne": "Include numeri",
    "reqPwLabelTwo": "Include lettere minuscole",
    "reqPwLabelThree": "Include lettare maiuscole",
    "reqPwLabelFour": "Include caratteri speciali",
    "insertPw": "Inserisci una nuova password.",
    "insertPwText": "Scegli una password complessa e univoca per la sicurezza.",
    "pwLabel": "La tua password",
    "confirmPwLabel": "La tua password",
    "loginFCPw": "Login",
    "validateCode": "Il codice di verifica deve essere composto da 6 lettere",
    "validateEmail": "Email non valida",
    "insertCodeTitle": "Inserisci codice di conferma",
    "insertCodeText": "Per completare la registrazione inserisci il codice che abbiamo inviato al tuo indirizzo email.",
    "codeField": "Codice di conferma",
    "confirmReg": "Conferma",
    "firstStep": "Prima fase",
    "firstStepDesc": "Imposta MFA",
    "setupMfaTitle": "Imposta il tuo codice MFA.",
    "firstStepText": "Incrementa la sicurezza del tuo account tramite l'autenticazione a più fattori",
    "firstStepButton": "Imposta MFA",
    "secondStep": "Seconda fase",
    "connectAuth": "Connetti un Authenticator",
    "connectAuthApp": "Collega un'applicazione di autenticazione",
    "clickLink": "Fare clic sul collegamento per completare l'autenticazione",
    "mobileLink": "Link per dispositivi mobili",
    "manualConnection": "Oppure aggiungi manualmente il seguente codice all'interno della tua app di autenticazione",
    "scanQR": "Oppure scansione il QR Code",
    "authRequired": "Solo dopo aver collegato un Authenticator puoi procedere con la Verifica",
    "backMfa": "Indietro",
    "goVerification": "Vai alla Verifica",
    "lastStep": "Ultima fase",
    "lastStepDesc": "Codice di verifica",
    "verifyMfa": "Verifica il tuo codice MFA.",
    "insertMfa": "Inserisci il codice di conferma per concludere la configurazione del MFA.",
    "alertMfa": "Puoi verificare il tuo codice solo dopo aver collegato con successo un Authenticator",
    "validatePw": "Password invalida",
    "modalRemoveMfa": "Stai per rimuovere l'Autenticazione a due fattori (MFA) dal tuo account.",
    "modalRemoveConfirm": "Clicca per confermare la tua scelta",
    "modalLabelConfirm": "Disabilita",
    "modalLabelCancel": "Annulla",
    "mfaSetup": "Hai correttamente impostato l'autenticazione a due fattori (MFA) sul tuo account.",
    "mfaSetupText": "Hai rafforzato la sicurezza del tuo account.",
    "removeMfa": "Rimuovi MFA",
    "removeMfaInstruction": "Se vuoi rimuovere l'Autenticazione a due fattori (MFA), inserisci la tua password e poi premi il pulsante qui sotto",
    "emailS": "E-mail",
    "phoneS": "Telefono",
    "addressS": "Indirizzo",
    "workS": "Orario di lavoro",
    "fontDecrease": "Diminuisci dimensione font",
    "fontIncrease": "Aumenta dimensione font",
    "selectLanguage": "Seleziona la lingua",
    "selectTheme": "Attiva modalità",
    "themeLight": "diurna",
    "themeDark": "notturna",
    "docTitle": "BECOME | Tutti i corsi",
    "eLearning": "Piattaforma E-Learning | ECM",
    "inThisPage": "In questa pagina potrai visualizzare i corsi offerti da BECOME.",
    "currentDisabled": "Sezione momentaneamente disabilitata",
    "corsiNoECM": "Corsi di formazione asincroni senza ECM",
    "whatYouFind": "Qui puoi trovare i corsi ad accesso libero e rivedere gratuitamente i corsi ECM frequentati.",
    "goCourses": "Vai ai corsi liberi",
    "modalTitleP": "Nuovo paziente",
    "patientAlert": "Per motivi di sicurezza e privacy preferiamo non archiviare i dati dei pazienti sui nostri server. Ricordati di scrivere il codice paziente in un libro/agenda fisica.",
    "labelCode": "Codice",
    "myDevices": "I miei dispositivi",
    "setUpDevice": "Configura un nuovo dispositivo",
    "serverConnect": "Connessione al server:",
    "sConnecting": "Tentativo di connessione in corso",
    "sOpen": "Connesso",
    "sClosing": "Disconnessione in corso",
    "sClose": "Disconnesso",
    "sUninstantiated": "Non istanziato",
    "deviceName": "Nome:",
    "recordingStart": "Inizia a registrare",
    "recordingStop": "Ferma la registrazione",
    "heartRate": "Frequenza cardiaca",
    "foodListID": "Deve essere presente una Lista cibi ",
    "patientID": "Deve essere presente un Paziente",
    "deviceID": "Deve essere presente un Dispositivo",
    "ristorante": "Ristorante",
    "cucina": "Cucina",
    "bedroom": "Camera da letto",
    "stanza": "Seleziona un ambiente valido",
    "bozza": "Salva come bozza",
    "coda": "Salva e metti in coda",
    "statusStatus": "Seleziona uno stato valido",
    "newConfig": "Nuova sessione",
    "foodList": "Seleziona una lista cibi",
    "searchByName": "Cerca per nome",
    "selectPatient": "Seleziona un codice paziente",
    "searchPatient": "Cerca per codice paziente",
    "selectDevice": "Seleziona un dispositivo",
    "pickOne": "Seleziona un dispositivo",
    "labelRecord": "Registra risultati Fitbit?",
    "labelStatus": "Stato",
    "buttonCreate": "Crea",
    "validateName": "Il nome deve essere lungo almeno 3 lettere",
    "actionId": "Id: ",
    "actionName": "Nome: ",
    "modalTitle": "Nuova lista cibi",
    "alertWarning": "Attenzione!",
    "warningText": "Una volta creata la lista non sarà più possibile modificarla, in quanto andrebbe ad invalidare i risultati già memorizzati.",
    "food": "Cibo",
    "foodListName": "Nome lista",
    "foodListCreate": "Crea lista",
    "deleteConfirm": "Confermi la cancellazione? ###",
    "deleteWarning": "Cancellando la lista cibi cancellerai anche tutte le sessioni e i relativi risultati.",
    "deleteBCancel": "Annulla",
    "deleteBDelete": "Cancella",
    "breadcrumbFoodList": "Liste cibi",
    "nameTable": "Nome",
    "totalFoodTable": "Numero di cibi",
    "totalConfigurationsTable": "Numero di sessioni",
    "tableActions": "Azioni",
    "fetchingLoading": "Caricamento...",
    "fetchingNoResults": "Nessun risultato",
    "breadcrumbsAllApps": "Tutte le apps",
    "breadcrumbPatients": "Pazienti",
    "tableCode": "Codice",
    "tableCreateAt": "Creato il",
    "buttonEsplora": "Esplora",
    "allApps": "Tutte le apps",
    "alertUseApp": "Per iniziare ad utilizzare questa applicazione contattare gentilmente il nostro supporto a ",
    "baseline": "Baseline",
    "variation": "Variazione",
    "labelRoom": "Seleziona lo scenario",
    "totalPatients": "Pazienti",
    "totalPatientsLabel": "Servono più pazienti? Effettua un upgrade del piano attuale",
    "upgradePlan": "Upgrade plan",
    "allPatients": "Tutti i pazienti",
    "addPatient": "Aggiungi paziente",
    "totatListsFood": "Liste cibi",
    "totatListsFoodLabel": "Servono più liste cibi? Effettua un upgrade del piano attuale",
    "goToLists": "Vai alle liste",
    "createList": "Crea nuova lista",
    "deviceTotal": "Dispositivi",
    "upgradeLabelDevice": "Servono più dispositivi? Effettua un upgrade del piano attuale",
    "goToDevice": "Vai ai dispositivi",
    "liveRegister": "Registrazione live",
    "sessioni": "Sessioni",
    "upgradeLabelConfig": "Servono più sessioni? Effettua un upgrade del piano attuale",
    "oneList": "Hai almeno una lista cibi?",
    "answerYes": "Si",
    "answerNo": "No",
    "performance_location": "Scenario",
    "food_location": "luogo",
    "onePatient": "Hai almeno un paziente?",
    "oneDevice": "Hai almeno un dispositivo?",
    "allSessionsB": "Tutte le sessioni",
    "allSessionsLabel": "Aggiungi almeno un paziente e crea una lista cibi prima di creare una sessione",
    "newSession": "Nuova sessione",
    "queuedConfigurations": "Sessioni in coda",
    "titleRoom": "Ambiente",
    "titlePatient": "Paziente",
    "titleDevice": "Dispositivo",
    "titleFoodList": "Lista cibi",
    "titleActions": "Azioni",
    "fetchLoading": "Caricamento...",
    "fetchNoResults": "Nessun risultato",
    "sidebarHome": "Home",
    "sidebarCourses": "Corsi",
    "sidebarApp": "App",
    "sidebarMaterials": "Materiali",
    "sidebarProfessionals": "Pagina professionisti",
    "sidebarDevices": "Dispositivi",
    "sidebarSecurity": "Sicurezza",
    "sidebarSupport": "Supporto",
    "aCDeleteConf": "Conferma la cancellazione della sessione tramite id: ",
    "aCAlert": "La cancellazione di questa sessione eliminerà anche tutti i risultati correlati.",
    "aCCancel": "Annulla",
    "aCDelete": "Cancella",
    "aCSessionId": "Id sessione:",
    "aCRoom": "Sessione",
    "aCPatient": "Codice paziente",
    "aCFoodList": "Nome lista cibi",
    "aCCompleted": "Completata il",
    "aCFood": "Cibi",
    "aCLevel": "Livello",
    "aCDownload": "Scarica CSV",
    "aCBreadcrumbsApps": "Tutte le apps",
    "aCBreadcrumbsConfig": "Sessioni",
    "aCPaziente": "Paziente",
    "aCPatients": "Pazienti",
    "aCPatientsSearch": "Cerca paziente...",
    "aCDevice": "Dispositivo",
    "aCfoodListT": "Lista cibi",
    "aCStatus": "Stato",
    "aCREC": "REG",
    "aCCreatedAt": "Creata il:",
    "aCActions": "Azioni",
    "aCLoading": "Caricamento...",
    "aCNoResults": "Nessun risultato",
    "loginWithQR": "Accedi con il QRCODE.",
    "devicesMain": "Dispositivi",
    "devicesMainText": "In questa pagina potrai trovare i token di accesso alle applicazioni Become.",
    "infoD": "Informazioni",
    "warningAlert": "Per autenticarsi su un dispositivo, inserisci la ### One Time Password ### di un token (se è \"disponibile\"). ### Se hai finito tutti i token disponibili e hai bisogno di autenticarti su un dispositivo, contatta il nostro ",
    "warningAlertSupport": "supporto",
    "modalConnect": "Connetti un altro dispositivo",
    "modalConnectButton": "Connettiti",
    "tableDeviceName": "Nome del dispositivo",
    "tableDeviceType": "Tipologia",
    "tableOneTime": "One Time Password",
    "tableConnections": "Dispositivi connessi",
    "tableConnect": "Connettiti",
    "tableQR": "QR Code",
    "corsiTitleTwo": "Iscriviti alla nostra newsletter per non perdere i corsi in partenza per psicologi, medici, psicoterapeuti, neuroscienziati, ricercatori e studenti universitari.",
    "videoPlayer": "Anteprima del corso",
    "courseDetailsAuthor": "Autori:",
    "courseDetailsLastUpdate": "Aggiornato il:",
    "includeApp": "App inclusa",
    "tax": "+ IVA",
    "appTitle": "LE NOSTRE APP DI REALTÀ VIRTUALE",
    "appSubTitle": "I nostri strumenti innovativi di Realtà Virtuale e Biosensoristica per professionisti della salute mentale - psicologi, medici, psicoterapeuti, neuroscienziati, ricercatori - sono in continuo aggiornamento.",
    "appSubTwoTitle": "Scopri le nostre app, le nostre esperienze immersive e le nostre psicotecnologie per la pratica clinica, riabilitativa, organizzativa, sportiva, scolastica e del benessere.",
    "categoryS": "Categoria selezionata:",
    "resetFilter": "Cancella filtri",
    "youCart": "Il tuo carrello",
    "labelRemoveCart": "Rimuovi dal carrello",
    "removeCart": "Rimuovi",
    "orderSummary": "Riepilogo ordine:",
    "subtotale": "Subtotale",
    "taxText": "Tasse",
    "taxToPay": "Calcolate alla checkout",
    "toOrder": "Procedi all'Ordine",
    "productPrice": ",00",
    "inCart": "Già nel carrello",
    "addCart": "Aggiungi al carrello",
    "buyNow": "Acquista ora",
    "whatYouWLearn": "Cosa imparerai",
    "courseContent": "Ambiti del corso",
    "courseDescr": "Descrizione del corso",
    "ambitoDelCorso": "Ambiti del corso",
    "becomeText": "BECOME crea soluzioni tecnologiche e formative innovative per la salute mentale",
    "getStarted": "Inizia ora",
    "liveDemo": "Live demo",
    "myOrder": "Il mio ordine:",
    "paginaprincipale": "Home",
    "corsi": "Corsi",
    "applicazioni": "App",
    "profes": "Sei un professionista?",
    "azienda": "Sei un azienda?",
    "kitchen": "Cucina",
    "dining_room": "Sala da pranzo",
    "ricerca": "Ricerca",
    "goToCart": "Vedi tutto il carrello",
    "corsiTitle": "I NOSTRI CORSI ECM",
    "filteR": "Filtri",
    "topic": "Topic",
    "durata": "Durata",
    "dettagli": "Dettagli",
    "soluzioni": "Soluzioni",
    "piattaforma": "Vai alla piattaforma",
    "accedi": "Accedi",
    "registrati": "Registrati",
    "notturna": "Attiva modalità notturna",
    "diurna": "Attiva modalità diurna",
    "viewCart": "Visualizza il carrello",
    "professionistiTitle": "Professionisti Certificati",
    "professionistiPlaceholder": "Ricerca per nome, indirizzo o regione...",
    "professionistiLabel": "Cerca",
    "profAddress": "Nessun indirizzo inserito",
    "professione": "Professione non indicata",
    "noCertificati": "Nessun certificato",
    "profWebsite": "Sito Personale",
    "aCSelectPH": "Filtra per stato...",
    "pACRooms": "Stanze",
    "selectRoom": "Seleziona un ambiente",
    "volcano": "Vulcano",
    "ocean": "Oceano",
    "connectTitle": "Connetti un nuovo dispositivo",
    "loginFromMobile": "Accedi da cellulare",
    "professionalTitle": "Da questa sezione puoi abilitare il tuo profilo pubblico sulla pagina professionisti",
    "labelProfile": "Vuoi comparire nella pagina Professionisti Certificati?",
    "labelNAME": "Nome",
    "labelSurname": "Cognome",
    "labelAddress": "Indirizzo",
    "labelState": "Regione",
    "labelProfession": "Professione",
    "labelBachelor": "Titolo",
    "placeholderBachelor": "Dott*",
    "labelWebsite": "Sito web",
    "SAVE": "Salva",
    "titleProfessional": "Pagina professionisti",
    "bombolone": "Bombolone",
    "cannolo": "Cannolo",
    "cannoncino": "Cannoncino",
    "caramelle": "Caramelle",
    "cioccolato": "Cioccolato",
    "cono": "Cono",
    "cookies": "Cookies",
    "croissant": "Croissant",
    "crostata": "Crostata",
    "cupcake": "Cupcake",
    "donut": "Donut",
    "macarons": "Macarons",
    "meringa": "Meringa",
    "muffin": "Muffin",
    "panettone": "Panettone",
    "pasticcino_frutta": "Pasticcino alla Frutta",
    "praline": "Praline",
    "sacher": "Sacher",
    "saint_honoré": "Saint Honoré",
    "snack": "Snack",
    "snack_cioccolato": "Snack Cioccolato",
    "torta_margherita": "Torta Margherita",
    "torta_paradiso": "Torta Paradiso",
    "affogato": "Affogato",
    "baba": "Baba",
    "biscotti": "Biscotti",
    "barrette": "Barrette Cereali",
    "corn_flakes": "Corn Flakes",
    "ciliege": "Ciliege",
    "frutti_di_bosco": "Frutti di Bosco",
    "insalata": "Insalata",
    "macedonia": "Macedonia",
    "more": "More",
    "pere": "Pere",
    "pesche": "Pesche",
    "uva": "Uva",
    "yogurt": "Yogurt",
    "anguria": "Anguria",
    "arancia": "Arancia",
    "banana": "Banana",
    "carote": "Carote",
    "verdure_grigliate": "Verdure Grigliate",
    "pomodori": "Pomodori",
    "minestrone": "Minestrone",
    "insalata_orzo": "Insalata Orzo",
    "tartare_tonno": "Tartare Tonno",
    "pollo_ai_ferri": "Pollo ai ferri",
    "ricotta": "Ricotta",
    "riso": "Riso",
    "simmenthal": "Simmenthal",
    "sushi": "Sushi",
    "pesce": "Pesce",
    "pesce_alla_griglia": "Pesce alla Griglia",
    "pesce_spada": "Pesce Spada",
    "nighiri": "Nighiri",
    "fette_biscottate": "Fette Biscottate",
    "gamberi": "Gamberi",
    "pasta": "Pasta",
    "patatine_fritte": "Patatine Fritte",
    "pizza": "Pizza",
    "salame": "Salame",
    "salatini": "Salatini",
    "bresaola": "Bresaola",
    "salsiccia": "Salsiccia",
    "spiedini": "Spiedini",
    "stinco": "Stinco",
    "taco": "Taco",
    "tortilla": "Tortilla",
    "uovo_e_bacon": "Uovo e Bacon",
    "arachidi": "Arachidi",
    "arrosto": "Arrosto",
    "bistecca": "Bistecca",
    "bruschetta": "Bruschetta",
    "burger": "Burger",
    "calzone": "Calzone",
    "chips": "Chips",
    "coscia_di_pollo": "Coscia di Pollo",
    "cotoletta": "Cotoletta",
    "crackers": "Crackers",
    "crocchette": "Crocchette",
    "focaccia": "Focaccia",
    "formaggio": "Formaggio",
    "fritto_misto": "Fritto Misto",
    "gorgonzola": "Gorgonzola",
    "hamburger": "Hamburger",
    "hot_dog": "Hot Dog",
    "kebab": "Kebab",
    "lasagne": "Lasagne",
    "parmigiana": "Parmigiana",
    "a_cake": "A. Torta",
    "a_pizza": "A. Pizza",
    "a_chips": "A. Patatine fritte",
    "a_nuts": "A. Noccioline",
    "a_cookies": "A. Biscotti",
    "a_ice_cream": "A. Gelato",
    "b_pastel": "Pastel",
    "buffet": "Abilita buffet",
    "a_chocolate": "A. Cioccolata",
    "a_bread": "A. Pane",
    "a_donuts": "A. Donuts",
    "a_brownies": "A. Brownies",
    "a_spaghetti": "Ravioli",
    "a_rice": "A. Riso",
    "a_fruit": "A. Frutta",
    "a_salad": "A. Insalata",
    "a_yogurt": "A. Yogurt",
    "a_pancakes": "A. Pancakes",
    "a_lean_meat": "A. Tagliata",
    "a_fried_chicken": "A. Pollo fritto",
    "a_peanut_butter": "A. Burro di arachidi",
    "a_snacks": "A. Snacks",
    "a_popcorn": "A. Popcorn",
    "a_pastries": "A. Paste",
    "a_cereal": "A. Cereali",
    "a_bacon": "A. Bacon",
    "a_steak": "A. Bistecca",
    "a_baked_potato": "A. Patate",
    "a_cupcakes": "A. Cupcakes",
    "b_brigadeiro": "B. Brigadeiro",
    "b_joelho": "B. Joelho",
    "b_orange_juice": "B. Orange Juice",
    "b_soft_drink": "B. Soft Drink",
    "b_spaghetti_bolognese": "B. Pasta al ragù",
    "b_açai_bowl": "B. Açai Bowl",
    "b_paçoca": "B. Paçoca",
    "b_pão_de_queijo": "B. Pão de Queijo",
    "b_oreo": "B. Oreo",
    "b_beef_stroganoff": "B. Beef Stroganoff",
    "b_empada": "B. Empada",
    "b_coxinha_de_galinha": "B. Coxinha de Galinha",
    "difficulty": "Tempo di registrazione baseline (in secondi)",
    "hrMin": "Variazione in percentuale",
    "hrMax": "Durata sessione (in secondi)"
  }
}