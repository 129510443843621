import React, {useEffect, useState} from "react";
import {useDocumentTitle, useFavicon} from "@mantine/hooks";
import {Anchor, Avatar, Breadcrumbs, Divider, Flex, Loader, Modal, Text, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Vimeo from '@u-wave/react-vimeo';
import VideoCard from "./components/VideoCard";
import VideoService from "../../services/apps/VideoService";
import {genericError} from "../../functions/genericError";


const AugmentedWellbeing: React.FC = (mobile: number | "auto") => {

    useDocumentTitle('BECOME | Augmented Life Suite');
    useFavicon('/favicon.ico');

    const navigate = useNavigate();
    const {translations} = useSelector((state: any) => state.lang);
    const [selectedVideo, setSelectedVideo] = useState<any>();
    const [videos, setVideos] = useState([])
    const [loading, setIsLoading] = useState<boolean>(false)
    const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0)

    const fetch = () => {
        // chiamo il servizio di bvackend che recupera i video
        setIsLoading(true)
        VideoService.listVideos()
            .then((r => {
                console.log('videos', r)
                setVideos(r.filter((v) => !v.video.category.includes('3d')))
            }))
            .catch(genericError)
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        fetch();
    }, []);


    return <div>

        <Breadcrumbs mb={12}>
            <Anchor href={'#'} onClick={() => navigate('/apps')}>
                {translations.breadcrumbsAllApps ?? "translations.breadcrumbsAllApps"}
            </Anchor>
            <span>Augmented Life Suite</span>
        </Breadcrumbs>
        <Flex align={'center'}>
            <Avatar
                mr={24}
                src={'/images/AugmentedLifeSuite.png'} size={'xl'}/>
            <Title>Augmented Life Suite</Title>
        </Flex>
        <Divider mt={12} mb={12}/>


        {loading ? <Flex mt={24} justify={'center'} align={'center'}><Loader color="blue" size={60}/></Flex> :
            (videos.length > 0 ?
                    <Flex
                        mih={50}
                        gap="lg"
                        justify="center"
                        align="center"
                        direction="row"
                        wrap="wrap"
                    >
                        {videos.map((x, index) => <VideoCard key={'vc' + index} video={x.video}
                                                             setSelectedVideo={setSelectedVideo}/>)}
                        <VideoCard video={[572129372, 889641668, 699844408]}
                                   image={'/images/Become_Augmented_Wellbeing_Logo.png'}
                                   setSelectedVideo={setSelectedVideo}/>
                    </Flex>
                    :
                    <>
                        <Flex><p>Al momento al suo profilo non sono collegati video.</p></Flex>
                    </>
            )}

        {/*
        modale: se videoId esiste si vede, se no no => open
        bottone chiudi modale al click => setVideoId(undefined)
        */}

        <Modal
            opened={selectedVideo !== undefined}
            onClose={() => setSelectedVideo(undefined)}
            title={selectedVideo?.title}
            centered
            size={"xl"}
            closeOnEscape
        >
            {
                selectedVideo ? (
                        selectedVideo.s3_folder ? <Vimeo
                            video={selectedVideo.s3_folder}
                            autoplay={false}
                            showPortrait={false}
                            showByline={false}
                            showTitle={false}
                            responsive={true}
                            dnt={true}
                        /> : <Vimeo
                            video={selectedVideo[selectedVideoIndex]}
                            autoplay={true}
                            onEnd={() => {
                                let i = selectedVideoIndex + 1;
                                if (i >= selectedVideo.length) {
                                    i = 0;
                                }
                                setSelectedVideoIndex(i)
                            }}

                            showPortrait={false}
                            showByline={false}
                            showTitle={false}
                            responsive={true}
                            dnt={true}
                        />
                    ) :
                    null
            }
            <Divider mt={12} mb={12}/>
            <div><Text>{selectedVideo?.short_description}</Text></div>
        </Modal>

    </div>
}


export default AugmentedWellbeing;