import {
    Anchor,
    Box,
    Button,
    Checkbox,
    Container,
    Image,
    LoadingOverlay,
    Paper,
    PasswordInput,
    Popover,
    Progress,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { useForm } from "@mantine/form";
import { IconCheck, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import md5 from 'crypto-js/md5';
import AuthService from "../../services/AuthService";
import { genericError } from "../../functions/genericError";
import { useNavigate } from "react-router-dom";
import { setAuthState, setEmail } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";


export function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text
            color={meets ? 'teal' : 'red'}
            sx={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
        >
            {meets ? <IconCheck size="0.9rem"/> : <IconX size="0.9rem"/>} <Box ml={10}>{label}</Box>
        </Text>
    );
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number', translationKey: 'reqPwLabelOne' },
    { re: /[a-z]/, label: 'Includes lowercase letter', translationKey: 'reqPwLabelTwo' },
    { re: /[A-Z]/, label: 'Includes uppercase letter', translationKey: 'reqPwLabelThree' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol', translationKey: 'reqPwLabelFour' },
];

const data = [
    'Abruzzo',
    'Basilicata',
    'Bolzano',
    'Calabria',
    'Campania',
    'Emilia Romagna',
    'Friuli Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardia',
    'Marche',
    'Molise',
    'Piemonte',
    'Puglia',
    'Sardegna',
    'Sicilia',
    'Toscana',
    'Trento',
    'Umbria',
    'Valle d\'Aosta',
    'Veneto'
];

export function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if ( !requirement.re.test(password) ) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export default function Registration() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { translations } = useSelector((state: any) => state.lang);

    const form: any = useForm({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            order_of_psychologists: "",
            order_number: "",
            termsOfService: false,
        },

        validate: {
            name: (value) => (value.length > 3 ? null : translations.invalidName),
            password: (value) => (/[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[$&+,:;=?@#|'<>.^*()%!-]/.test(value) ? null : translations.invalidPw),
            confirm_password: (value) => (value === form.values.password ? null : translations.pwNotMatch),
            termsOfService: (value: boolean) =>
                value ? null : 'You must accept the terms and conditions',
            // order_of_psychologists: (value) => null
        },
    });

    const strength = getStrength(form.values.password);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={translations[requirement.translationKey] ?? "reqPwLabel da 1 a 4"}
                             meets={requirement.re.test(form.values.password)}/>
    ));
    const onSubmit = (values: any) => {
        setInProgress(true)
        const hash = md5(values.password).toString();

        AuthService.register({
            name: values.name,
            email: values.email,
            password: hash,
            order_of_psychologists: values.order_of_psychologists,
            order_number: values.order_number
        })
            .then(r => {
                dispatch(setEmail(values.email))
                dispatch(setAuthState('CONFIRM_REGISTRATION'))
                navigate('/');
            })
            .catch(genericError)
            .finally(() => setInProgress(false))
    }

    return (
        <Container size={420} my={40}>
            <LoadingOverlay visible={inProgress} overlayBlur={2} transitionDuration={1000}/>
            <Image
                width={120}
                height={120}
                ml={'auto'}
                mr={'auto'}
                mb={12}
                src={'https://images.squarespace-cdn.com/content/v1/5eb2b51167fdef19fd2e97f2/1591627430987-54ZRIMAKRRBYYEYITTR4/Become_Augmented_Life_Logo.png?format=1500w'}/>
            <Title
                align="center"
                sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
            >
                {translations.createAcc ?? "translations.createAcc"}
            </Title>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <LoadingOverlay visible={inProgress} overlayBlur={2}/>
                        <TextInput label={translations.fullName ?? "translations.fullName"} placeholder="Paolo Rossi"
                                   required {...form.getInputProps('name')}/>
                        <TextInput label={translations.emailR ?? "translations.emailR"}
                                   placeholder="paolo.rossi@gmail.com"
                                   required {...form.getInputProps('email')}/>
                        <Select label={translations.order_of_psychologists ?? "translations.order_of_psychologists"}
                                data={data}
                                clearable
                                {...form.getInputProps('order_of_psychologists')}/>

                        {form.values.order_of_psychologists &&
                            <TextInput label={translations.order_number ?? "translations.order_number"}
                                       required={form.values.order_of_psychologists === 'Lombardia'}
                                       placeholder=""
                                       {...form.getInputProps('order_number')}/>
                        }


                        <Popover opened={popoverOpened} position="bottom" width="target"
                                 transitionProps={{ transition: 'pop' }}>
                            <Popover.Target>
                                <div
                                    onFocusCapture={() => setPopoverOpened(true)}
                                    onBlurCapture={() => setPopoverOpened(false)}
                                >
                                    <PasswordInput
                                        withAsterisk
                                        label={translations.passwordR ?? "translations.passwordR"}
                                        placeholder={translations.passwordR ?? "translations.passwordR"}
                                        {...form.getInputProps('password')}
                                    />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Progress color={color} value={strength} size={5} mb="xs"/>
                                <PasswordRequirement label={translations.passwordLabel ?? "translations.passwordLabel"}
                                                     meets={form.values.password.length > 5}/>
                                {checks}
                            </Popover.Dropdown>
                        </Popover>
                        <PasswordInput
                            withAsterisk
                            label={translations.confirmPw ?? "translations.confirmPw"}
                            placeholder={translations.confirmPw ?? "translations.confirmPw"}
                            {...form.getInputProps('confirm_password')}
                        />

                        <Checkbox
                            label={
                                <>
                                    {translations.IAccept}{' '} <a
                                    href='https://discoverbecome.com/it/eula'>{translations.termsAndConditions}</a>
                                    {translations.and}
                                    <a
                                        href='https://discoverbecome.com/it/privacy'>{translations.privacyPolicy}</a>
                                </>
                            }
                            required
                            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
                        />


                        <Button fullWidth mt="xl" disabled={form.values.password.length <= 5} type={'submit'}>
                            {translations.registerR ?? "translations.registerR"}
                        </Button>

                    </Stack>
                </form>
                <Text color="dimmed" size="sm" align="center" mt={12}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <Anchor size="sm" ml={12}
                            component="button" onClick={() => navigate('/')}>
                        {translations.backLogin ?? "translations.backLogin"}
                    </Anchor>
                </Text>
            </Paper>

        </Container>
    );
}
